const translationKey = 'API_ERROR_HANDLING.CART_AND_CHECKOUT.REVIEW.ERROR';

const OrderReviewConstants = {
  ERROR_KEYS: {
    _ERR_FORTER_DOWN_BOPIS: `${translationKey}._ERR_FORTER_DOWN_BOPIS`,
    _ERR_FORTER_DENIED_BOPIS: `${translationKey}._ERR_FORTER_DENIED_BOPIS`,
    _ERR_CARD_DENIED_BOPIS: `${translationKey}._ERR_CARD_DENIED_BOPIS`,
    _ERR_JDE_CONNECTION_REFUSED_BOPIS: `${translationKey}._ERR_JDE_CONNECTION_REFUSED_BOPIS`,
    _ERR_FORTER_DENIED_D365: `${translationKey}._ERR_FORTER_DENIED_D365`,
    _ERR_CARD_DENIED_DC: `${translationKey}._ERR_CARD_DENIED_DC`,
    _ERR_FORTER_DENIED_DC: `${translationKey}._ERR_FORTER_DENIED_DC`,
    _ERR_ORD_INVENTORY: `${translationKey}._ERR_ORD_INVENTORY`,
    _TREVIPAY_ERROR_GENERIC: `${translationKey}._ERR_GENERIC_TREVIPAY`,
  },
  WITH_LIFT_GATE: 'with Lift Gate',
  LIFTGATE_YES: 'LIFTGATE_YES',
  BOPIS_AVAILABILITY: 'bopisAvailability',
  BOPIS_ERROR_CODE: 'EOI_001',
  BOPIS_ERROR_KEY: '_ERR_ORD_INVENTORY',
  CARD_DENIED_KEY: '_ERR_CARD_DENIED_DC',
  CARD_DENIED_BOPIS_KEY: '_ERR_CARD_DENIED_BOPIS',
  BOPIS_ERROR_MESSAGE: (storeName: string) =>
    `The items in your order are no longer available for Store Pickup at the ${storeName} Northerntool Store.`,
  CHANGE_YOUR_DELIVERY_METHOD:
    'Change your Delivery Options or Change Store for Pickup',
  BOPIS_CARD_DENIED_MESSAGE: (storeName: string) =>
    `Call the ${storeName} Northern Tool + Equipment Store to reserve your items and pay at the store.`,
  UNABLE_TO_PROCESS_MESSAGE:
    'We are unable to process your chosen payment method online. You may either:',
  CHOOSE_NEW_PAYMENT: 'Choose a new payment type and try again, or',
  FACTORY_SHIPPED: 'DELIVERY_OPTION.FACTORY_SHIPPED',
  GET_IT: 'Get it by',
  SEE_DELIVERY_DATES_BELOW: 'See below for delivery dates by item.',
  REVIEW_ORDER_ESPOT: 'REVIEW-ORDER_01',
};

export { OrderReviewConstants };
