import { AxiosRequestConfig } from 'axios';
import { trackUserEvent } from '../../../utils/lucky-orange';
import { makeRequest, makeUnauthorizedRequest } from '../axios/axiosConfig';
import {
  CardDetailsUpdateResponse,
  FetchCardDetailsResponse,
  ICardTokenization,
} from './IPayment';
import { PaymentServiceConstants } from './payment.service.constants';

/**
 * @interface IAddCreditCard
 */
export interface IAddCreditCard {
  storeID: string;
  token: string;
  expiryMonth: string;
  expiryYear: string;
  cardType: string;
}

export interface ITokenexResponse {
  Error: string | null;
  Success: boolean;
  ReferenceNumber: string;
  Token: string;
  TokenHMAC: string;
  FirstEight: null;
}

/**
 * Payment Service to Save and update card details, process payments.
 */
const PaymentService = {
  /**
   * @method addCreditCard adds a tokenized value of credit card details.
   *
   * @param storeID
   */
  async addCreditCard({
    storeID,
    token,
    expiryMonth,
    expiryYear,
    cardType,
  }: IAddCreditCard): Promise<CardDetailsUpdateResponse> {
    const { CARD_DETAILS_URL } = PaymentServiceConstants;

    const cardDetailsPayload = {
      orderId: '',
      valueFromProfileOrder: 'Y',
      pay_payment_method: 'Token',
      pay_payMethodId: 'Token',
      pay_account: token,
      pay_temp_account: token,
      pay_cc_brand: cardType,
      curr_year: new Date().getFullYear(),
      curr_month: new Date().getMonth() + 1,
      curr_date: new Date().getDate(),
      is_valid: true,
      cc_brand: cardType,
      cc_number: token,
      payMethodId: 'Token',
      creditCard: 'creditCardSelected',
      pay_expire_month: expiryMonth,
      pay_expire_year: expiryYear,
    };

    try {
      const addCardDetailsRequest: AxiosRequestConfig = {
        url: CARD_DETAILS_URL(storeID),
        method: 'PUT',
        data: cardDetailsPayload,
      };

      const addCardDetailsResponse: any = await makeRequest(
        addCardDetailsRequest
      );

      return addCardDetailsResponse;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'Add Credit Card' });

      throw e;
    }
  },

  /**
   * @method addNET30Card adds NET30 card details to backend.
   *
   * @param storeID
   */
  async addNET30Card({
    storeID,
    cardNumber,
  }: any): Promise<CardDetailsUpdateResponse> {
    const { CARD_DETAILS_URL } = PaymentServiceConstants;

    const cardDetailsPayload = {
      orderId: '',
      valueFromProfileOrder: 'Y',
      pay_payment_method: 'NT Net 30 Account',
      pay_payMethodId: 'NT Net 30 Account',
      pay_account: cardNumber,
      pay_temp_account: cardNumber,
      pay_cc_brand: 'NT Net 30 Account',
      curr_year: new Date().getFullYear(),
      curr_month: new Date().getMonth() + 1,
      curr_date: new Date().getDate(),
      is_valid: false,
      cc_brand: 'NT Net 30 Account',
      cc_number: cardNumber,
      payMethodId: 'NT Net 30 Account',
      account_Net_30: cardNumber,
      creditCard: '',
      pay_expire_month: '',
      pay_expire_year: '',
    };

    try {
      const addCardDetailsRequest: AxiosRequestConfig = {
        url: CARD_DETAILS_URL(storeID),
        method: 'PUT',
        data: cardDetailsPayload,
      };

      const addCardDetailsResponse: any = await makeRequest(
        addCardDetailsRequest
      );

      return addCardDetailsResponse;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'Add Net30 Card' });

      throw e;
    }
  },

  /**
   * @method addTreviPayAccount adds TreviPay account details to backend.
   *
   * @param storeID
   */
  async addTreviPayAccount({
    storeID,
    buyerId,
  }: any): Promise<CardDetailsUpdateResponse> {
    const { CARD_DETAILS_URL } = PaymentServiceConstants;

    const treviPayDetailsPayload = {
      orderId: '',
      valueFromProfileOrder: 'Y',
      pay_payment_method: 'TREVIPAY',
      pay_payMethodId: 'TREVIPAY',
      pay_account: buyerId,
      pay_temp_account: buyerId,
      pay_cc_brand: 'TREVIPAY',
      curr_year: new Date().getFullYear(),
      curr_month: new Date().getMonth() + 1,
      curr_date: new Date().getDate(),
      is_valid: true,
      cc_brand: 'TREVIPAY',
      cc_number: buyerId,
      payMethodId: 'TREVIPAY',
      account_Net_30: '',
      creditCard: 'TREVIPAY',
      pay_expire_month: '',
      pay_expire_year: '',
    };

    try {
      const addTreviPayDetailsRequest: AxiosRequestConfig = {
        url: CARD_DETAILS_URL(storeID),
        method: 'PUT',
        data: treviPayDetailsPayload,
      };

      const addTreviPayDetailsResponse: any = await makeRequest(
        addTreviPayDetailsRequest
      );

      return addTreviPayDetailsResponse;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'TREVIPAY' });

      throw e;
    }
  },

  /**
   * @method updateExpiryDateForCreditCard update Expiry month and year for credit/debit card..
   *
   * @param storeID
   */
  async updateExpiryDateForCreditCard({
    storeID,
    expiryMonth,
    expiryYear,
  }: any): Promise<CardDetailsUpdateResponse> {
    const { CARD_DETAILS_URL } = PaymentServiceConstants;

    const cardDetailsPayload = {
      pay_expire_month: expiryMonth,
      pay_expire_year: expiryYear,
    };

    try {
      const updateCardDetailsRequest: AxiosRequestConfig = {
        url: CARD_DETAILS_URL(storeID),
        method: 'PUT',
        data: cardDetailsPayload,
      };

      const updateCardDetailsResponset: any = await makeRequest(
        updateCardDetailsRequest
      );

      return updateCardDetailsResponset;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'Update Credit Card Expiry' });

      throw e;
    }
  },

  /**
   * @method getSavedCardDetails adds a tokenized value of credit card details.
   *
   * @param storeID
   */
  async getSavedCardDetails({
    storeID,
  }: any): Promise<FetchCardDetailsResponse> {
    const { CARD_DETAILS_URL } = PaymentServiceConstants;

    try {
      const getSavedCardDetailsRequest: AxiosRequestConfig = {
        url: CARD_DETAILS_URL(storeID),
      };

      const getSavedCardDetailsResponse: any = await makeRequest(
        getSavedCardDetailsRequest
      );

      return getSavedCardDetailsResponse;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'savedCardGet' });

      throw e;
    }
  },

  /**
   * @method deleteSavedCardDetails adds a tokenized value of credit card details.
   *
   * @param storeID
   */
  async deleteSavedCardDetails({
    storeID,
  }: any): Promise<CardDetailsUpdateResponse> {
    const { CARD_DETAILS_URL } = PaymentServiceConstants;

    try {
      const getSavedCardDetailsRequest: AxiosRequestConfig = {
        url: CARD_DETAILS_URL(storeID),
        method: 'DELETE',
      };

      const getSavedCardDetailsResponse: any = await makeRequest(
        getSavedCardDetailsRequest
      );

      return getSavedCardDetailsResponse;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'savedCardDelete' });

      throw e;
    }
  },

  /**
   * @method getIframeAuthenticationKey fetches Authentication Key for Iframe.
   *
   * @param storeID
   */
  async getIframeAuthenticationKey({
    storeID,
    history,
  }: any): Promise<ICardTokenization> {
    const { AUTHENTICATION_KEY_URL } = PaymentServiceConstants;

    try {
      const getSavedCardDetailsRequest: AxiosRequestConfig = {
        url: AUTHENTICATION_KEY_URL(storeID),
      };

      const authenticationKeyResponse: ICardTokenization = await makeRequest(
        getSavedCardDetailsRequest,
        undefined,
        history
      );

      return authenticationKeyResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'tokenExAuthenticationKey',
      });

      throw e;
    }
  },

  async tokenizeCreditOrDebitCard({
    cardNumber,
    authKey,
    timeStamp,
  }: any): Promise<any> {
    try {
      const tokenizeCardRequest: AxiosRequestConfig = {
        url: process.env.REACT_APP_TOKENX_API_SERVER,
        method: 'POST',
        data: {
          tokenexid: process.env.REACT_APP_NT_TOKENX_ID,
          timestamp: timeStamp,
          authenticationKey: authKey,
          data: cardNumber,
          tokenScheme: process.env.REACT_APP_NT_TOKENX_SCHEME,
          useExtendedBin: false,
        },
      };

      const tokenizedResponse: ITokenexResponse = await makeUnauthorizedRequest(
        tokenizeCardRequest
      );

      if (tokenizedResponse.Success) {
        return tokenizedResponse;
      } else {
        throw tokenizedResponse;
      }
    } catch (e) {
      throw e;
    }
  },
};

export { PaymentService };
